import type { ShareSeries, ShareType } from '@elseu/sdu-evidend-graphql';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
// eslint-disable-next-line import/no-duplicates
import type { Locale } from 'date-fns';
import { formatDistanceToNow, isBefore, subDays } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { enGB as dateFnsLocaleEnGb, nl as dateFnsLocaleNl } from 'date-fns/locale';
import dayjs from 'dayjs';
import { countryNames } from 'forms/helpers/countryCodeOptions';
import { isEmptyDate } from 'forms/helpers/dateTransform';
import { sortBy } from 'lodash';

import { formatMoney, moneyToNumber } from './money';

export const formatShareTypeName = (
  { classifier, name, nominalValue }: Pick<ShareType, 'classifier' | 'name' | 'nominalValue'>,
  showNominalValue = true,
) => {
  const shareTypeName = `${name}${classifier ? `: ${classifier}` : ''}`;
  if (!showNominalValue) return shareTypeName;
  return `${shareTypeName} (${formatMoney(nominalValue, { round: false })})`;
};

export const formatShareSeries = ({ from, to, shareType }: ShareSeries) => {
  const prefix = shareType.classifier ? `${shareType.classifier} ` : '';
  if (from === to) {
    return `${prefix}${formatNumber(from)}`;
  }

  return `${prefix}${formatNumber(from)} - ${prefix}${formatNumber(to)}`;
};

export const formatDate = (date?: Date | null, format = 'LL') => {
  if (isEmptyDate(date)) return t`Onbekend`;
  return dayjs(date).locale(i18n.locale).format(format);
};

export const formatDateFromNow = (date: Date, days = 3) => {
  if (isEmptyDate(date)) return '';
  if (isBefore(date, subDays(new Date(), days))) {
    return formatDate(date);
  }
  const locales: Record<string, Locale> = {
    'en-gb': dateFnsLocaleEnGb,
    nl: dateFnsLocaleNl,
  };
  return formatDistanceToNow(date, { locale: locales[i18n.locale], addSuffix: true });
};

export const formatNumber = (
  number: number,
  options: Intl.NumberFormatOptions | undefined = undefined,
): string => {
  return new Intl.NumberFormat(i18n.locale, options).format(number);
};

export const formatCountryByCode = (isoCode: string): string => {
  const locales: Record<string, string> = {
    'en-gb': 'en',
    nl: 'nl',
  };
  return countryNames(locales[i18n.locale])[isoCode];
};

export const capitalizeString = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);

export const sortShareTypes = (shareTypes: ShareType[]): ShareType[] => {
  return sortBy(shareTypes, [
    'classifier',
    (shareType) => moneyToNumber(shareType.nominalValue),
    'name',
  ]);
};
