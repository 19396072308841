import Head from 'next/head';
import React from 'react';

interface IPageTitle {
  title: string;
}
export const PageTitle = ({ title }: IPageTitle) => (
  <Head>
    <title>{title} | Evidend</title>
  </Head>
);
