import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import countries from 'i18n-iso-countries';

export const countryNames = (locale: string): Record<string, string> => ({
  ...countries.getNames(locale, { select: 'official' }),
  CS: t`Servië en Montenegro`,
  AN: t`Nederlandse Antillen`,
});

export const countryCodeOptions = () => {
  const locales: Record<string, string> = {
    'en-gb': 'en',
    nl: 'nl',
  };
  const countryObject = countryNames(locales[i18n.locale]);
  const countryCodes = Object.keys(countryObject);

  const countryCodeOptions = countryCodes.map((countryCode) => ({
    value: countryCode,
    label: countryObject[countryCode],
  }));

  // Sort by translated name.
  countryCodeOptions.sort((a, b) => a.label.localeCompare(b.label));

  return countryCodeOptions;
};
