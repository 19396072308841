import type { Name } from '@elseu/sdu-evidend-graphql';

const getLetter = (value?: string | null): string | undefined =>
  value?.match(/[a-zA-Z]/)?.[0].toUpperCase();

export const getNameInitials = ({ lastName, firstName, initials, nickname }: Name): string => {
  const [lastLetter, fnl, inl, nnl] = [lastName, firstName, initials, nickname].map(getLetter);
  const firstLetter = [fnl, inl, nnl].filter(Boolean)[0];

  if (firstLetter) {
    return `${firstLetter}${lastLetter}`;
  }

  return lastLetter as string;
};

export const getInitials = (name: string): string => {
  const parts = name
    .split(/\s+/)
    .filter((part) => /^[A-Z]/.exec(part))
    .map((part) => part.charAt(0));
  const firstLetter = parts.shift();
  const lastLetter = parts.pop();
  return `${firstLetter || ''}${lastLetter || ''}`;
};
