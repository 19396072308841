import type { GraphQLErrors } from '@apollo/client/errors';
import type { Maybe, ValidationError, ValidationErrorParam } from '@elseu/sdu-evidend-graphql';
import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import type { SetGlobalError } from 'hooks/useGlobalError';
import { camelCase, get, isArray, isPlainObject, map, reduce, uniqBy, zipObject } from 'lodash';
import type { UseFormReturn } from 'react-hook-form';

/**
 * Returns all the paths inside an object.
 *
 * Example:
 *
 *   getObjectPaths({
 *     company: {
 *       shareTypes: [
 *         { paid: true, nominalValue: 123.45 },
 *         { paid: false, nominalValue: 67.89 },
 *       ],
 *       incorporatedOn: new Date(),
 *       statutoryName: 'Company Name',
 *     },
 *   });
 *
 * will return:
 *
 *   [
 *     "company.shareTypes.0.paid",
 *     "company.shareTypes.0.nominalValue",
 *     "company.shareTypes.1.paid",
 *     "company.shareTypes.1.nominalValue",
 *     "company.incorporatedOn",
 *     "company.statutoryName",
 *   ]
 */
const getObjectPaths = (values: any) => {
  const reducer = (aggregator: string[], val: string, key: string) => {
    let paths = [key];
    if (isPlainObject(val) || isArray(val)) {
      paths = reduce<any, string[]>(val, reducer, []);
      paths = paths.map((path) => `${key}.${path}`);
    }
    aggregator.push(...paths);
    return aggregator;
  };
  return reduce<any, string[]>(values, reducer, []);
};

export const hasErrorsForPath = (errors: any, path: string) => {
  return getErrorsForPath(errors, path) !== undefined;
};
export const getErrorsForPath = (errors: any, path: string) => {
  return get(errors, path.replace(/\.$/, ''));
};

// Converts `/some_field/child/2/another_field` to `someField.child.2.anotherField`.
const normalizeField = (field?: Maybe<string>) =>
  field?.split('/').map(camelCase).filter(Boolean).join('.');

export const normalizeErrorFields = (errors: ValidationError[]) => {
  // Normalize all error fields.
  return errors.map(
    (error) =>
      ({
        ...error,
        field: normalizeField(error.field),
      } as ValidationError),
  );
};

const alreadyInUseError = (args?: Record<string, string>) =>
  args
    ? i18n._(
        /* i18n */ 'Er bestaat al een register voor de rechtspersoon met dit KVK-nummer binnen Evidend. Je kunt contact opnemen met {managedBy}.',
        args,
      )
    : t`Er bestaat al een register voor de rechtspersoon met dit KVK-nummer binnen Evidend.`;

const errorMessages = {
  ACCESS_REQUEST_ALREADY_HAS_ACCESS: () => t`Deze gebruiker heeft al toegang`,
  ACCESS_REQUEST_CURRENT_USER_NO_ADMIN_ENTITLEMENT: () => t`Deze gebruiker kan geen toegang geven`,
  ACCESS_REQUEST_NOT_FOUND: () => t`Het verzoek om toegang is niet gevonden`,
  ACCESS_REQUEST_USER_NOT_FOUND: () => t`De gebruiker is niet gevonden`,
  BUYER_ALREADY_MAPPED_TO_NOTARY: () => t`Deze koper is al gekoppeld aan een groep`,
  BUYER_NOT_FOUND: () => t`Deze koper is niet gevonden`,
  DELEGATION_DETAILS_NOT_SET: () => t`Er zijn verplichte velden niet ingevuld`,
  DOCUMENT_FILE_NOT_UPLOADED: () => t`Dit document heeft geen bestand gekoppeld`,
  DOCUMENT_ILLEGAL_PARTY_CHANGE: () => t`De gekoppelde partij kan niet worden gewijzigd`,
  DOCUMENT_IS_IN_USE: () => t`Het document wordt gebruikt en kan niet worden verwijderd`,
  DOCUMENT_NOT_FOUND: () => t`Het document is niet gevonden`,
  ENTITLEMENTS_ALREADY_INVITED: () => t`Deze gebruiker is al uitgenodigd`,
  ENTITLEMENTS_DUPLICATE: () => t`Er zijn dubbele rechten geselecteerd`,
  ENTITLEMENTS_LAST_ADMIN_REMOVED: () => t`Er moet minimaal één gebruiker met beheerrechten zijn.`,
  ENTITLEMENTS_NO_SUCH_USER: () =>
    t`Deze gebruiker is niet bekend in Evidend. Op dit moment kan alleen toegang gegeven worden aan bestaande gebruikers.`,
  GENERIC_JSON_VALIDATION_ERROR: () => t`De ingevulde waarde is niet geldig`,
  GROUP_NOT_FOUND: () => t`Deze groep is niet gevonden`,
  HAS_DRAFT_ALREADY: () => t`Er bestaat al een concept mutatie voor deze partij`,
  HAS_INCORRECT_MUTATION: () => t`Deze partij heeft een onjuiste mutatie`,
  ILLEGAL_TRADE_REGISTER_NUMBER_ADD: () =>
    t`Kan geen kvk nummer toevoegen aan een bestaand register`,
  ILLEGAL_TRADE_REGISTER_NUMBER_CHANGE: () =>
    t`Je kunt het kvk nummer van deze rechtspersoon niet wijzigen`,
  ILLEGAL_TRADE_REGISTER_NUMBER_SET: () =>
    t`Je kunt het kvk nummer van deze rechtspersoon niet opslaan`,
  INVALID_ARGUMENT: () => t`De ingevulde waarde is niet geldig`,
  INVALID_CAPITAL_SHARE: () => t`Het ingevulde kapitaal is niet geldig`,
  INVALID_EMAIL_ADDRESS: () => t`Het ingevulde e-mailadres is niet geldig`,
  INVALID_FORMAT: () => t`De ingevulde waarde heeft niet het juiste formaat`,
  INVALID_IBAN: () => t`Het ingevulde IBAN-nummer is niet geldig`,
  INVALID_MIME_TYPE: () => t`Het bestandstype is niet toegestaan`,
  INVALID_TYPE: () => t`De ingevulde waarde is niet geldig`,
  INVITATION_ALREADY_ACCEPTED: () => t`De uitnodiging is verlopen`,
  INVITATION_EXPIRED: () => t`De uitnodiging is verlopen`,
  INVITATION_NOT_FOUND: () => t`De uitnodiging is verlopen`,
  ISSUED_SHARES_MORE_THAN_AUTHORIZED: () =>
    t`Het geplaatste kapitaal kan niet groter zijn dan het maatschappelijk kapitaal`,
  KVK_DATA_TEMPORARY_UNAVAILABLE: () =>
    t`De gegevens zijn tijdelijk niet leverbaar omdat deze in behandeling zijn. Probeer het later nog eens.`,
  KVK_DATA_UNAVAILABLE: () =>
    t`De gegevens voor dit nummer zijn niet beschikbaar door een aantekening (noodprocedure).`,
  KVK_GENERAL_ERROR: () =>
    t`Er is een onverwachte fout opgetreden, neem contact op met de klantenservice.`,
  KVK_INVALID_NUMBER: () => t`Het opgegeven nummer voldoet niet aan zijn formaat.`,
  KVK_NA: () => t`Het opgegeven nummer is niet toepasbaar.`,
  KVK_NO_DATA_REGISTERED: () => t`Er is geen data beschikbaar voor dit nummer.`,
  KVK_TECHNICAL_ERROR: () =>
    t`De gegevens kunnen niet worden opgehaald door een technische storing bij het handelsregister.`,
  KVK_UNKNOWN_NUMBER: () => t`Het opgegeven nummer is niet ingeschreven in het handelsregister.`,
  LEGAL_ENTITY_EXISTS: () => t`Deze rechtspersoon bestaat al`,
  MAGIC_LINK_ALREADY_EXPIRED: () => t`De opgegeven link is verlopen`,
  MAGIC_LINK_LOGIN_FAILED: () => t`De opgegeven link is niet geldig`,
  MAGIC_LINK_NOT_FOUND: () => t`De opgegeven link is niet gevonden`,
  MAGIC_LINK_SEND_FAILED: () => t`De link kan niet worden verzonden`,
  MISSING_REQUIRED: () => t`Er zijn verplichte velden niet ingevuld`,
  MUST_NOT_BE_NULL: () => t`Dit veld is verplicht`,
  MUTATION_AFTER_LIQUIDATION_NOT_ALLOWED: () =>
    t`Kan geen mutatie invoeren na het beëindigen van het register`,
  MUTATION_EFFECTIVE_DATE_SHARE_TYPE_CONSTRAINT: () =>
    t`De datum van de mutatie mag niet voor de datum van de soorten aandelen liggen`,
  MUTATION_NOT_FOUND: () => t`De mutatie is niet gevonden`,
  MUTATION_NOT_LATEST: () => t`De datum kan niet liggen voor de datum van een bestaande mutatie`,
  MUTATION_RANGE_COLLISION: () => t`De ingevulde reeks heeft overlap met andere reeksen`,
  MUTATION_TRANSFER_SAME_SHAREHOLDER: () =>
    t`De vervreemder en verkrijger kunnen niet dezelfde persoon zijn`,
  MUTATION_WRONG_DEED_PASSED_DATE: () =>
    t`De datum van de akte komt niet overeen met de uitvoerdatum van de mutatie`,
  MUTATION_WRONG_DEED_PASSED_TYPE: () =>
    t`Het type van de akte komt niet overeen met het type van de mutatie`,
  MUTATION_WRONG_DOCUMENT_PARTY: () =>
    t`Het document moet aan dezelfde persoon gekoppeld zijn als de mutatie`,
  MUTATION_WRONG_DOCUMENT: () => t`Het document bestaat niet of is niet beschikbaar`,
  MUTATION_WRONG_EFFECTIVE_DATE: () => t`De ingangsdatum van de mutatie is ongeldig`,
  MUTATION_WRONG_PARTY_CONTEXT: () => t`De persoon bestaat niet of is niet beschikbaar`,
  NOTARY_NAME_NOT_SET: () => t`De naam van de notaris is niet ingevuld`,
  OPERATION_NOT_PERMITTED_ON_CURRENT_ENV: () => t`Deze actie is niet toegestaan in deze omgeving`,
  PARTY_HAS_ACTIVE_PARTICIPATIONS: () => t`Deze partij heeft nog actieve participaties`,
  PARTY_HAS_NO_REGISTER: () => t`Deze partij heeft geen register`,
  PARTY_ID_CAN_NOT_BE_EMPTY: () => t`Geef een ID op voor de partij`,
  PARTY_MUTATION_PERMISSION_DENIED: () =>
    t`Je hebt niet genoeg rechten om aanpassingen te doen aan deze (rechts)persoon`,
  PARTY_NOT_FOUND: () => t`De partij kan niet worden gevonden`,
  PARTY_NOT_IN_REGISTER: () => t`Deze partij is niet gekoppeld aan dit register`,
  PARTY_UPDATE_ALREADY_APPLIED: () => t`De wijzigingen zijn al toegepast`,
  PARTY_UPDATE_MISSING_DOCUMENT: () => t`De wijzigingen hebben geen document`,
  PARTY_UPDATE_NOT_FOUND_FOR_PARTY: () => t`De wijzigingen zijn niet gevonden voor deze partij`,
  PARTY_UPDATE_NOT_FOUND: () => t`De wijzigingen zijn niet gevonden`,
  PARTY_UPDATE_ORDER_CONFLICT: () =>
    t`Deze wijzigingen kunnen niet worden opgeslagen. Er zijn al wijzigingen na de door u opgegeven datum.`,
  PATTERN_MISMATCH: () => t`De ingevulde waarde voldoet niet aan het verwachte formaat`,
  PERMISSION_DENIED: () => t`Toegang geweigerd`,
  POSTAL_ADDRESS_LOOKUP_NOT_FOUND: () => t`De ingevulde postcode is niet gevonden`,
  S3_OBJECT_NOT_FOUND: () => t`Het bestand is niet gevonden`,
  SERVICE_USAGE_EXCEEDED: () => t`Het limiet voor het gebruik van deze service is bereikt`,
  SHARE_GROUP_ENTRY_NOT_FOUND: () => t`Deze groepswaarde is niet gevonden`,
  SHARE_GROUP_FOR_GROUP_ENTRY_NOT_FOUND: () => t`Deze groep is niet gevonden`,
  SHARE_GROUP_NOT_FOUND: () => t`Deze groep is niet gevonden`,
  SHARE_RANGE_ALREADY_ATTACHED: () => t`De geselecteerde reeks bevat aandelen die al beslag hebben`,
  SHARE_RANGE_ALREADY_NOT_ATTACHED: () =>
    t`De geselecteerde reeks bevat aandelen die al geen beslag meer hebben`,
  SHARE_RANGE_ALREADY_NOT_PLEDGED: () =>
    t`De geselecteerde reeks bevat aandelen die al geen verpanding meer hebben`,
  SHARE_RANGE_ALREADY_NOT_RULED: () =>
    t`De geselecteerde reeks bevat aandelen die al geen bewind meer hebben`,
  SHARE_RANGE_ALREADY_NOT_USUFRUCTED: () =>
    t`De geselecteerde reeks bevat aandelen die al geen vruchtgebruik meer hebben`,
  SHARE_RANGE_ALREADY_PAID: () => t`De geselecteerde reeks bevat aandelen die al zijn volgestort`,
  SHARE_RANGE_ALREADY_PLEDGED: () => t`De geselecteerde reeks bevat aandelen die al verpand zijn`,
  SHARE_RANGE_ALREADY_RULED: () =>
    t`De geselecteerde reeks bevat aandelen die al onder bewind staan`,
  SHARE_RANGE_ALREADY_USUFRUCTED: () =>
    t`De geselecteerde reeks bevat aandelen die al vruchtgebruik hebben`,
  SHARE_RANGE_ATTACHED: ({ mutationName = t`mutatie` }: { mutationName?: string } = {}) =>
    i18n._(/* i18n */ 'Er is beslag gelegd op deze aandelen, {mutationName} niet mogelijk.', {
      mutationName,
    }),
  SHARE_RANGE_CANT_CHANGE: () => t`De reeks kan niet worden gewijzigd`,
  SHARE_RANGE_COLLISION: () => t`De ingevulde reeks heeft overlap met bestaande reeksen`,
  SHARE_RANGE_DIFFERENT_PARTY: () => t`Alle reeksen moeten toebehoren aan dezelfde partij`,
  SHARE_RANGE_ID_EMPTY: () => t`De unieke waarde is niet aangemaakt voor de reeks`,
  SHARE_RANGE_INVALID_ID: () => t`De ingevulde reeks bestaat niet`,
  SHARE_RANGE_INVALID_TYPE: () => t`Het ingevulde soort aandelen bestaat niet`,
  SHARE_RANGE_INVALID: () => t`Ongeldige reeks`,
  SHARE_RANGE_MISSING_PAID_STATUS: () => t`De reeks heeft geen betaalstatus`,
  SHARE_RANGE_NOT_OWNED: () => t`Vervreemder bezit de ingevulde aandelen niet`,
  SHARE_RANGE_OWNED_IS_INSUFFICIENT: () =>
    t`Vervreemder bezit niet voldoende aandelen van het geselecteerde type`,
  SHARE_RANGE_OWNER_NOT_SET: () => t`De geselecteerde reeks heeft geen eigenaar`,
  SHARE_RANGE_PAID_STATUS_NOT_APPLICABLE: () => t`De reeks heeft geen betaalstatus`,
  SHARE_TYPE_ALREADY_ENDED: () => t`Het soort aandelen is al beëindigd`,
  SHARE_TYPE_EFFECTIVE_DATE_MUTATION_CONSTRAINT: () =>
    t`Een soort aandelen wordt gebruikt in een eerdere mutatie`,
  SHARE_TYPE_EXISTS: () => t`Een soort aandelen met dezelfde gegevens bestaat al`,
  SHARE_TYPE_IN_USE: () =>
    t`Deze soort wordt gebruikt in een mutatie en kan niet worden verwijderd`,
  SHARE_TYPE_IS_USED_IN_PREVIOUS_MUTATIONS: () =>
    t`Het soort aandelen wordt gebruikt in een eerdere mutatie`,
  SHARE_TYPE_MEETING: () => t`Soort aandelen is geen certificaat en heeft geen vergaderrecht`,
  SHARE_TYPE_NOT_FOUND: () => t`Ongeldig soort aandelen`,
  SHARE_TYPE_NOT_POINTING_TO_MUTATION: () =>
    t`Het soort aandelen is niet gekoppeld aan een mutatie`,
  SHARE_TYPE_VERSION_EXISTS: () => t`Er bestaat al een soort met deze gegevens`,
  SHARE_TYPE_VERSION_LINKING_CONFLICT: () =>
    t`Het soort aandelen kon niet worden gekoppeld aan deze mutatie`,
  SHARE_TYPE_VERSION_NOT_FOUND_FOR_DATE: () =>
    t`Het soort aandelen is niet gevonden voor deze datum`,
  SHARE_TYPE_VERSION_NOT_FOUND: () => t`Het soort aandelen is niet gevonden`,
  STRING_TOO_LONG: () => t`De ingevulde waarde is te lang`,
  STRING_TOO_SHORT: () => t`De ingevulde waarde is te kort`,
  TEMPORARY_ACCESS_CAN_ONLY_BE_CREATED_FOR_VIEWING_RIGHTS: () =>
    t`Kan geen tijdelijke gebruiker aanmaken met deze rechten`,
  TEMPORARY_USER_CAN_ONLY_HAVE_TEMPORARY_VIEW_ACCESS: () =>
    t`Kan geen tijdelijke gebruiker aanmaken met deze rechten`,
  TEMPORARY_GROUP_CAN_ONLY_HAVE_TEMPORARY_VIEW_ACCESS: () =>
    t`Kan geen tijdelijke gebruiker aanmaken met deze rechten`,
  TEMPORARY_ACCESS_CANNOT_BE_ASSIGNED_TO_REGULAR_USER: () =>
    t`Kan tijdelijke rechten niet toewijzen aan een bestaande gebruiker`,
  TEMPORARY_ACCESS_CANNOT_BE_ASSIGNED_TO_REGULAR_GROUP: () =>
    t`Kan tijdelijke rechten niet toewijzen aan een bestaande gebruiker`,
  TEMPORARY_GROUP_CANNOT_BE_REASSIGNED: () => t`Kan gebruiker niet aan tijdelijke groep toewijzen`,
  TEMPORARY_USER_CANNOT_BE_UPDATED: () => t`Kan tijdelijke gebruiker niet wijzigen`,
  TRADE_REGISTER_NUMBER_ALREADY_IN_USE: alreadyInUseError,
  UNKNOWN_ERROR: () => t`Onbekende fout`,
  USER_HAS_NON_TEMPORARY_ACCOUNT: () => t`Deze gebruiker heeft een niet-tijdelijk account`,
  USER_NOT_FOUND: () => t`Gebruiker niet gevonden`,
  WORKFLOW_KVK_ALREADY_INCORPORATED: alreadyInUseError,
  WORKFLOW_WRONG_MUTATION_TYPE: () =>
    t`De mutaties binnen een workflow moeten van hetzelfde type zijn als de workflow zelf`,
  WORKFLOW_WRONG_TYPE: () => t`De workflow heeft het verkeerde type`,
};

export type ValidationErrorCode = keyof typeof errorMessages;

/**
 * Generates the user-readable error message for a ValidationError.
 */
export const formErrorMessage = (error: ValidationError | undefined): string => {
  if (error) {
    /**
     * ValidationError codes that can be passed by the backend and their
     * user-readable error message.
     *
     * Parameters that are passed with these error codes can be found in the repository
     * `sdu-evidend-core` in files `src/main/java/batavia/common/errortranslator/*` and
     * should be used as named parameter using curly braces.
     */
    const { code, params } = error;
    if (code && code in errorMessages) {
      const message = errorMessages[code as keyof typeof errorMessages];
      const args = zipObject(map(params, 'name'), map(params, 'value'));
      return message(args);
    }
  }
  console.warn(error);
  return t`De ingevulde waarde is niet geldig`;
};

export const validationErrorParams = (validationError: ValidationError) =>
  Object.fromEntries(validationError.params.map(({ value, name }) => [name, value]));

export const addValidationErrorParam =
  (addParams: ValidationErrorParam[]) => (error: ValidationError) => {
    error.params.push(...addParams);
    return error;
  };

export type SetFormErrorsProps = {
  errors: GraphQLErrors | ValidationError[];
  form?: UseFormReturn<any>;
  setGlobalError: SetGlobalError;
  /** this may be a last restort or quick fix, key will be replaced in path by the value at that key */
  replaceValidationPart?: (path: string, error: ValidationError) => string;
  addParams?: ValidationErrorParam[];
};

/**
 * Given an array of ValidationErrors, any error with a `field` that exists within the form
 * is set on the appropriate field. Remaining errors, with or without a `field`, are displayed
 * in a global error message.
 */
export const setFormErrors = ({
  errors,
  form,
  replaceValidationPart,
  setGlobalError,
  addParams = [],
}: SetFormErrorsProps) => {
  // Determine valid errors paths.
  const validPaths = getObjectPaths(form ? form.getValues() : []);

  // Keep a list of global errors.
  const globalErrors: ValidationError[] = [];
  const validationErrors: ValidationError[] = errors
    .map((error) => {
      // Code does not exist on GraphQLError
      if ((error as undefined | { code: string })?.code) return error;
      // Try to return validationError
      return (error as undefined | { extensions?: { validationError: ValidationError } })
        ?.extensions?.validationError;
    })
    // Filter out empty errors
    .filter((e) => e) as ValidationError[];

  const validationErrorsWithParams = addParams.length
    ? validationErrors.map(addValidationErrorParam(addParams))
    : validationErrors;

  validationErrorsWithParams.forEach((error) => {
    if (!error.field) {
      globalErrors.push(error);
      return;
    }

    const splitPath = (error.field || '').split('/');
    const path = (
      !replaceValidationPart
        ? splitPath
        : splitPath.map((v) => replaceValidationPart(v, error)).filter((v) => !!v)
    ).join('.');

    const message = formErrorMessage(error);
    // Errors for paths we don't recognize are shown as global errors.
    if (!validPaths.includes(path)) {
      globalErrors.push(error);
      return;
    }
    if (form)
      form.setError(path, {
        type: 'server',
        message,
      });
  });

  const globalErrorMessages = uniqBy(
    globalErrors.map((error) => ({ key: error.code, message: formErrorMessage(error) })),
    ({ key }) => key,
  );
  setGlobalError(globalErrorMessages);
};
