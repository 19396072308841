import type { Money } from '@elseu/sdu-evidend-graphql';
import { i18n } from '@lingui/core';
import * as Sentry from '@sentry/nextjs';
import Big from 'big.js';
import { config } from 'config';

export const defaultCurrencyCode = 'EUR';

// The backend still has Money objects without a precision.
const defaultPrecision = (precision?: number | undefined) => precision || 2;

// The number the cents property is factor of, based on the provided precision.
const centFactor = (precision: number): number => Math.pow(10, precision);

export const isSameCurrency = (...monies: Money[]): boolean => {
  if (!monies.length) return true;
  const currencyCode = monies[0].currencyCode;
  return monies.every((money) => money.currencyCode === currencyCode);
};

// Converts a Money object to a Big object for easier manipulation.
const moneyToBig = (money: Money): Big => {
  const precision = defaultPrecision(money.precision);
  return Big(`${money.units}.${Math.abs(money.cents).toFixed(0).padStart(precision, '0')}`);
};

// Converts a Big object to a Money object.
const bigToMoney = (big: Big, currencyCode: string, precision = 9): Money => {
  const [units, optionalCents] = big.toFixed(precision).split('.').map(Number);
  // If precision is 0, optionalCents will be undefined.
  const cents = optionalCents || 0;

  return {
    units,
    cents,
    precision,
    currencyCode,
  };
};

// Convert a Money object to a string.
export const moneyToString = (money: Money): string =>
  moneyToBig(money).toFixed(defaultPrecision(money.precision));

// Convert a string into a Money object.
interface IStringToMoney {
  value: number;
  currencyCode: string;
  precision?: number;
}
export const stringToMoney = ({ value, currencyCode, precision = 9 }: IStringToMoney): Money => {
  const [units, cents] = Big(value).toFixed(precision).split('.').map(Number);

  return {
    units,
    cents,
    precision,
    currencyCode,
  };
};

// Convert a Money object to a number.
export const moneyToNumber = (money: Money): number => moneyToBig(money).toNumber();

// Convert a number into a Money object.
interface NumberToMoneyProps {
  value: number;
  currencyCode: string;
  precision?: number;
}
export const numberToMoney = ({
  value,
  currencyCode,
  precision = 9,
}: NumberToMoneyProps): Money => {
  const factor = centFactor(precision);
  const units = Math.floor(value);

  // Ignore decimal digits beyond provided precision.
  const decimals = Big(value).mod(1).times(factor).toNumber();
  const cents = Math.floor(decimals);

  return {
    units,
    cents,
    precision,
    currencyCode,
  };
};

// Multiply a Money object by a given multiplier.
export const multiplyMoney = (money: Money, multiplier: number): Money =>
  bigToMoney(
    moneyToBig(money).times(multiplier),
    money.currencyCode,
    defaultPrecision(money.precision),
  );

// Add an array of Money objects together.
export const addMoney = (...monies: Money[]): Money => {
  if (!monies.length)
    return {
      units: 0,
      cents: 0,
      precision: defaultPrecision(),
      currencyCode: defaultCurrencyCode,
    };
  if (!isSameCurrency(...monies)) {
    throw new Error("Can't add Money objects with different currency codes.");
  }

  const currencyCode = monies[0].currencyCode;
  // We keep the highest precision.
  const precision = Math.max(...monies.map((money) => defaultPrecision(money.precision)));

  return bigToMoney(
    monies.map(moneyToBig).reduce((a, b) => a.plus(b)),
    currencyCode,
    precision,
  );
};

// Format a Money object to a string.
export const formatMoney = (money: Money, { round = true } = {}): string => {
  const precision = round ? 2 : defaultPrecision(money.precision);

  // The Money object uses a Number for the `units` part, which limits the maximum value
  // to `Number.MAX_SAFE_INTEGER` (9007199254740991). Because we want to use Intl.NumberFormat
  // we still convert the rounded Big number to a Number. This means we lower the maximum value
  // by an order of 10^2.
  const number = Number(moneyToBig(money).toFixed(precision, Big.roundHalfUp));

  // Negative values (could) indicate too large number from backend.
  if (number < 0) {
    if (config.sentryOptions) {
      Sentry.captureMessage('Encountered negative amount');
    }
    return 'onbekend bedrag';
  }

  return formatCurrency(money.currencyCode, precision, number);
};

export const formatCurrency = (currency: string, precision: number, number: number): string => {
  return new Intl.NumberFormat(i18n.locale, {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: precision,
  }).format(number);
};
