import {
  Button,
  ButtonGroup,
  Drawer,
  DrawerContainer,
  DrawerContent,
  DrawerFooter,
  Flex,
} from '@elseu/sdu-titan';
import { yupResolver } from '@hookform/resolvers/yup';
import { Trans } from '@lingui/macro';
import { useLeaveConfirmCallback } from 'components/LeavePrompt';
import type { ReactNode } from 'react';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import type { DeepPartial, FieldValues, UseFormReturn } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';
import type * as yup from 'yup';
interface ISidebarFormContext {
  setOnClose: (fn: () => any) => any;
}

const SidebarFormContext = React.createContext<ISidebarFormContext | undefined>(undefined);

const useSidebarFormContext = () => {
  const context = useContext(SidebarFormContext);
  if (context === undefined) {
    throw new Error('useSidebarFormContext can only be used inside SidebarFormContext.');
  }
  return context;
};

const StyledFlex = styled(Flex)`
  width: 100%;
`;

const StyledForm = styled.form`
  height: 100%;
`;

/** the FormSchema generic infers the schema and use it to make the onSave method type safe */
export interface SidebarFormProps<
  FormSchema extends yup.AnyObjectSchema,
  FormValues extends FieldValues = yup.InferType<FormSchema>,
> {
  header: ReactNode;
  form: ReactNode;
  footerComponent?: ReactNode;
  formSchema: FormSchema;
  defaultValues?: DeepPartial<FormValues> | null;
  onSubmit: (values: FormValues, form: UseFormReturn<FormValues>) => void;
  setDrawerClosed: (props: any) => void;
  submitButtonText?: ReactNode;
  subtitle?: ReactNode;
  successComponent?: ReactNode;
  skipCloseConfirmation?: boolean;
  isSubmitDisabled?: boolean;
  canSubmitNonDirty?: boolean;
}
export const SidebarFormContent = <FormSchema extends yup.AnyObjectSchema>({
  defaultValues,
  form: formComponent,
  formSchema,
  footerComponent,
  header,
  onSubmit,
  setDrawerClosed,
  submitButtonText,
  subtitle,
  successComponent,
  skipCloseConfirmation = false,
  isSubmitDisabled: isSubmitDisabledProp,
  canSubmitNonDirty = false,
}: SidebarFormProps<FormSchema>) => {
  const form = useForm({
    defaultValues: defaultValues || {},
    resolver: yupResolver(formSchema),
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  const { isSubmitSuccessful, isSubmitting, isDirty, isValid } = form.formState;
  const isSubmitDisabled =
    isSubmitDisabledProp || !isValid || isSubmitting || (!canSubmitNonDirty && !isDirty);

  const close = useLeaveConfirmCallback(skipCloseConfirmation ? false : isDirty, setDrawerClosed);
  const { setOnClose } = useSidebarFormContext();

  useEffect(() => {
    setOnClose(() => close);
  }, [close, setOnClose]);

  const handleSubmit = useCallback(
    (formValues: yup.Asserts<FormSchema>) => onSubmit(formValues, form),
    [form, onSubmit],
  );

  if (isSubmitSuccessful && successComponent) {
    return (
      <DrawerContainer>
        <DrawerContent>{successComponent}</DrawerContent>
      </DrawerContainer>
    );
  }

  return (
    <FormProvider {...form}>
      <StyledForm onSubmit={form.handleSubmit(handleSubmit)}>
        <DrawerContainer header={header} subtitle={subtitle}>
          <DrawerContent>{formComponent}</DrawerContent>
        </DrawerContainer>
        <DrawerFooter>
          <StyledFlex alignItems="center" justifyContent="space-between">
            {footerComponent || <Flex />}
            <ButtonGroup spaceBetween={4}>
              <Button variant="clear" onClick={close}>
                <Trans>Annuleren</Trans>
              </Button>
              <Button isDisabled={isSubmitDisabled} type="submit">
                {submitButtonText || <Trans>Opslaan</Trans>}
              </Button>
            </ButtonGroup>
          </StyledFlex>
        </DrawerFooter>
      </StyledForm>
    </FormProvider>
  );
};

interface SidebarFormContainerProps {
  children: any;
  isDrawerShown: boolean;
  width?: number;
  onClose?: () => void;
}

export const SidebarFormContainer = ({
  children,
  width,
  isDrawerShown,
}: SidebarFormContainerProps) => {
  const [onClose, setOnClose] = useState(undefined);
  const value = useMemo(() => ({ setOnClose }), []);

  return (
    <SidebarFormContext.Provider value={value}>
      <Drawer isShown={isDrawerShown} position="right" width={width || 480} onClose={onClose}>
        {children}
      </Drawer>
    </SidebarFormContext.Provider>
  );
};
