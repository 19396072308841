import { Input } from '@elseu/sdu-titan';
import { formFieldErrorProps } from 'components/FormFields/formFieldErrorProps';
import type { FocusEvent } from 'react';
import React, { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

export interface FormValueTransform {
  input: (value: any) => any;
  output: (value: any) => any;
}

type InputFieldProps = {
  label: string;
  name: string;
  transform?: FormValueTransform;
  isErrorHidden?: boolean;
  onBlur?: (event: React.FocusEvent) => any;
  [key: string]: any;
};

export const defaultTransform: FormValueTransform = {
  input: (value: any) => value || '',
  output: (value: any) => value || null,
};

const InputField = ({
  name,
  transform = defaultTransform,
  isErrorHidden = false,
  onBlur,
  ...props
}: InputFieldProps) => {
  const { control } = useFormContext();

  const handleBlur = useCallback(
    (handler: (e: FocusEvent) => void, event: FocusEvent) => {
      handler(event);
      onBlur?.(event);
    },
    [onBlur],
  );

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onBlur, onChange, ...field }, formState: { errors } }) => (
        <Input
          {...formFieldErrorProps(errors, name, isErrorHidden)}
          autoComplete="off"
          spellCheck="false"
          value={transform.input(value)}
          onBlur={(event) => handleBlur(onBlur, event)}
          onInputChange={(value) => onChange(transform.output(value))}
          {...props}
          {...field}
        />
      )}
    />
  );
};

InputField.defaultProps = {
  spaceAfter: 6,
};

export { InputField };
